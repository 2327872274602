import { POSITION, PluginOptions } from 'vue-toastification'
import IconCheckThick from '@/assets/icons/CheckThick.svg'
import IconClose from '@/assets/icons/Close.svg?component'
import IconInfo from '@/assets/icons/Info.svg?component'

const toastConfig: PluginOptions = {
  position: POSITION.BOTTOM_LEFT,
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: false,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  icon: true,
  transition: 'Vue-Toastification__fade',
  closeButton: IconClose,
  toastDefaults: {
    error: {
      icon: IconInfo,
    },
    success: {
      icon: IconCheckThick,
    },
    info: {
      icon: false,
    },
  },
}

export default toastConfig
