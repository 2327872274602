import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  class: "fill-current",
  "aria-hidden": "true"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M1.3501 11.9999C1.3501 6.11802 6.11826 1.34985 12.0001 1.34985C17.8819 1.34985 22.6501 6.11802 22.6501 11.9999C22.6501 17.8817 17.8819 22.6499 12.0001 22.6499C6.11826 22.6499 1.3501 17.8817 1.3501 11.9999ZM12.0001 2.64985C6.83623 2.64985 2.6501 6.83599 2.6501 11.9999C2.6501 17.1637 6.83623 21.3499 12.0001 21.3499C17.164 21.3499 21.3501 17.1637 21.3501 11.9999C21.3501 6.83599 17.164 2.64985 12.0001 2.64985ZM12.0001 10.3501C12.3591 10.3501 12.6501 10.6411 12.6501 11.0001V16.0001C12.6501 16.3591 12.3591 16.6501 12.0001 16.6501C11.6411 16.6501 11.3501 16.3591 11.3501 16.0001V11.0001C11.3501 10.6411 11.6411 10.3501 12.0001 10.3501ZM12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z"
    }, null, -1)
  ])))
}
export default { render: render }