import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  class: "fill-current",
  "aria-hidden": "true"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M3.92241 19.3705C3.72715 19.5658 3.72715 19.8823 3.92241 20.0776C4.11767 20.2729 4.43426 20.2729 4.62952 20.0776L12 12.7071L19.3706 20.0776C19.5658 20.2729 19.8824 20.2729 20.0777 20.0776C20.2729 19.8824 20.2729 19.5658 20.0777 19.3705L12.7071 12L20.0777 4.62939C20.273 4.43412 20.273 4.11754 20.0777 3.92228C19.8825 3.72702 19.5659 3.72702 19.3706 3.92228L12 11.2929L4.62944 3.92233C4.43418 3.72706 4.1176 3.72706 3.92234 3.92233C3.72707 4.11759 3.72707 4.43417 3.92234 4.62943L11.2929 12L3.92241 19.3705Z"
    }, null, -1)
  ])))
}
export default { render: render }